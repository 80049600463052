// import * as React from 'react';
import axios from 'axios';

import { dispatch } from 'store';
import {
    setColor,
    // setTimeZone,
    // setDibsStudioId,
    setSalesTaxRate,
    setStudioCancelTime,
    setTwilioUSNum,
    setSendConfimationEmailDibs,
    setSendSMSConfirmation,
} from 'store/slices/dibsstudio';
import { getApiUrl } from 'helpers';

const updateBasicConfigs = async (dibsId) => {
    // const basicurl = 'http://192.168.0.36:3001/api/v2/widget/get-basic-config';
    const basicurl = getApiUrl('/widget/get-basic-config');
    await axios
        .post(basicurl, { dibsStudioId: dibsId })
        .then((response) => {
            // console.log(
            //     `\n\n\nfrom UpdateBasicConfigs Action get basic config ${JSON.stringify(
            //         response.data,
            //         null,
            //         2,
            //     )}`,
            // );
            dispatch(setColor(response.data.color));
            dispatch(setStudioCancelTime(response.data.cancelTime));
            dispatch(setSendSMSConfirmation(response.data.sendSMSConfirmation));
            dispatch(setTwilioUSNum(response.data.twilioCustomNumUS));
            dispatch(
                setSendConfimationEmailDibs(
                    response.data.sendEmailConfirmationViaDibs,
                ),
            );
            dispatch(setSalesTaxRate(response.data.taxRate));
        })
        .catch((error) => {
            console.log(error);
        });

    return null;
};

export default updateBasicConfigs;
